import React from "react"
import Cards from "../components/BlogPage/Cards"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import RemoveMarkdown from "remove-markdown"
import truncate from "lodash.truncate"

export default function news({ data }) {
  const formatedData = data.allStrapiNewsBlog.nodes.map(e => ({
    title: e.Title,
    image: e.MainMedia,
    slug: e.slug,
    description: RemoveMarkdown(
      truncate(e.Content, {
        length: 150,
        separator: /,?\.* +/,
      }),
      { useImgAltText: false }
    ),
  }))

  return (
    <Layout relative={false}>
      <Cards posts={formatedData} className="mt-5" />
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiNewsBlog(sort: { fields: created_at, order: DESC }) {
      nodes {
        Title
        MainMedia {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 485, height: 300)
            }
          }
        }

        slug
        Content
        published_at
      }
      totalCount
    }
  }
`
