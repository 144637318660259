/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

export default function Cards({ posts }) {
  return (
    <StyledSection>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6  lg:grid-cols-3 px-6 pb-10 mx-auto mt-10"
      >
        {posts.map(post => (
          <li
            key={post.slug}
            className="bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <Link to={`/news/${post.slug}/`} className="hover:opacity-80">
              <div className="w-full flex flex-col items-center justify-between rounded-lg divide-gray-200">
                <GatsbyImage
                  image={post.image.localFile.childImageSharp.gatsbyImageData}
                  alt="French Wines"
                  className="shadow-md"
                />
                <div className="px-4 mb-6 text-zone">
                  <h3 className="text-gray-900 mx-auto mt-6 h-[75px] overflow-hidden hover:text-red-800">
                    {post.title}
                  </h3>
                  <p className="mx-auto h-[130px]">{post.description}</p>
                </div>
              </div>

              {/* <div className="flex text-xl justify-center items-center py-6 text-black border-t-[1px] border-grey-800 hover:text-red-800">
                Read More
              </div> */}
            </Link>
          </li>
        ))}
      </ul>
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  h3 {
    font-size: var(--mainheading);
    color: var(--headblack);
  }
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--parablack);
  }
  @media only screen and (max-width: 1025px) {
    ul {
      padding: 0;
      border-radius: 0;
      margin-top: 0;
      grid-template-columns: 1fr;
    }
    .gatsby-image-wrapper {
      width: 100% !important;
    }
    .text-zone {
      max-width: 500px;
    }

    h3,
    p {
      height: auto !important;
    }
  }
`
